<template>
  <div>
    <div>
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >

        <!-- Row Loop -->
        <b-row
          ref="row"
        >

          <!-- Cost -->
          <b-col md="2" class="mb-1">
            <b-form-group
              label="Кол-во баллов"
              label-for="cost"
            >
              <b-form-input
                v-model="cntUser"
                type="number"
                placeholder="32"
                :disabled="isGetPaymentUrl"
              />
            </b-form-group>
          </b-col>

          <!-- Profession -->
          <b-col
            lg="2"
            md="1"
            class="mb-1"
          >
            <div style="margin-bottom: 7px;">
              <small>Будет куплено</small>
            </div>
            <big>
              {{ cnt }} <feather-icon
                icon="WindIcon"
                color="red"
              /> <small>за</small> {{ Math.round(cnt / 30, 0) }} ₽
            </big>
          </b-col>
        </b-row>
        <b-row :class="{ 'text-danger' : Math.round(cnt / 30, 0) < 100 }">
          <b-col>
            <small> Минимальная стоимость покупки 100 ₽ </small>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <br>
    <b-button
      v-if="Math.round(cnt / 30, 0) >= 100 && !url"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      :disabled="isGetPaymentUrl"
      @click="getUrlAndRedirect"
    >
      <span>Оплатить {{ Math.round(cnt / 30, 0) }} ₽</span>
    </b-button>

    <b-button
      v-if="url"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      tag="a"
      href="url"
      target="_blank"
    >
      <span>Если страница оплаты не открылась, то нажмите на эту кнопку для перехода к оплате</span>
    </b-button>
  </div>
</template>

<script>
import {
  ref, onUnmounted, nextTick, watch,
} from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import {
  BRow, BCol, BForm, VBToggle, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'

import pointsStoreModule from '../pointsStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
  setup() {
    const isGetPaymentUrl = ref(false)
    const cnt = ref(3000)
    const cntUser = ref(3000)
    const url = ref('')
    const APP_STORE_MODULE_NAME = 'app-points-add'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, pointsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const items = ref([{
      id: 1,
      selected: 'male',
      selected1: 'designer',
      prevHeight: 0,
    }])

    watch(cntUser, () => {
      cnt.value = Math.round(cntUser.value / 30) * 30
    })

    const dropzoneFile = ref('')
    const drop = e => {
      const foo = e.dataTransfer.files[0]
      dropzoneFile.value = foo
    }
    const selectedFile = () => {
      const foo = document.querySelector('.dropzoneFile').files[0]
      dropzoneFile.value = foo
    }

    const clients = ref([])
    /*
    store.dispatch('app-excerpt/fetchClients')
      .then(response => { clients.value = response.data })
    */
    const itemFormBlankItem = {
      item: null,
      cost: 0,
      qty: 0,
      description: '',
    }

    const excerptData = ref({
      id: 5037,
      client: null,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],

      salesPerson: '',
      note: 'It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You!',
      paymentMethod: null,
    })

    const getUrlAndRedirect = () => {
      isGetPaymentUrl.value = true
      store.dispatch('app-points-add/fetchGetPaymentUrl', {
        // eslint-disable-next-line object-shorthand
        cnt: cnt.value,
      })
        .then(response => {
          if (response.data.Data.url) {
            url.value = response.data.Data.url
            window.location.href = url.value
          } else {
            // eslint-disable-next-line no-alert
            alert('Ссылка на плату не получена, обновите страницу и попробуйте ещё раз')
          }
          isGetPaymentUrl.value = false
        })
    }

    const addNewItemInItemForm = () => {
      this.$refs.form.style.overflow = 'hidden'
      this.excerptData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

      nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    }
    const removeItem = index => {
      items.value.splice(index, 1)
    }

    return {
      excerptData,
      clients,
      itemFormBlankItem,
      dropzoneFile,
      drop,
      selectedFile,
      items,
      getUrlAndRedirect,
      addNewItemInItemForm,
      removeItem,
      cnt,
      cntUser,
      url,
      isGetPaymentUrl,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.excerpt-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-excerpt.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
